import axios from "axios";
import jwt_decode from "jwt-decode";
import { apiUrl } from "./appConfig";

export const isAuthenticated = () => {
  const token = localStorage.getItem("leaflore-web-token");
  if (!token) {
    return false;
  }

  const decodedToken = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  if (decodedToken.exp < currentTime) {
    localStorage.removeItem("leaflore-web-token");
    return false;
  }

  return true;
}

export const isAdmin = () => {
  const token = localStorage.getItem("leaflore-web-token");
  if (!token) {
    return false;
  }

  const decodedToken = jwt_decode(token);
  return decodedToken.role === "admin";
}

export const isTokenExpired = () => {
  const token = localStorage.getItem("leaflore-web-token");
  if (!token) {
    return true;
  }

  const decodedToken = jwt_decode(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
}

export const isValidator = () => {
  const token = localStorage.getItem("leaflore-web-token");
  if (!token) {
    return false;
  }

  const decodedToken = jwt_decode(token);
  return decodedToken.role === "admin" || decodedToken.role === "validator";
}

export const login = async (username, password) => {
  try {
    const response = await axios.post(`${apiUrl}/login`, { username, password });
    const { token } = response.data;
    localStorage.setItem("leaflore-web-token", token);
    return token;
  } catch (error) {
    console.error("Login failed:", error);
    return null;
  }
}

export const speciesIds = [
  { label: "Rășinoase", value: "coniferous" },
  { label: "Foioase", value: "deciduous" },
  { label: "Fructiferi", value: "fruit-bearing" },
  { label: "Arbusti", value: "shrubs" },
];

export const speciesNames = {
  "coniferous": "Rășinoase",
  "deciduous": "Foioase",
  "fruit-bearing": "Fructiferi",
  "shrubs": "Arbusti"
};

const speciesColors = {
  'Foioase': '#480000',
  'Arbusti': '#5a436a',
  'Fructiferi': '#820000',
  'Rășinoase': '#149e05',
};
export const getSpeciesColor = (species) => speciesColors[species] || '#cccccc';
